//redux/store.js
import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from './slices/authSlice'
import { roleReducer } from './slices/userRoleSlice'
import { companyReducer } from './slices/company'
import { workerReducer } from './slices/worker'
import { userReducer } from './slices/user'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    role: roleReducer,
    company: companyReducer,
    worker: workerReducer,
    user: userReducer,
  },
})