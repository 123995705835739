import React from "react";

const Step7 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Technische Anforderungen</h2>
      </div>
      <form>
        <label>
          Haben Sie spezielle technische Anforderungen oder Präferenzen?
          <textarea
            name="technicalRequirements"
            value={formData.technicalRequirements}
            onChange={handleChange}
          ></textarea>
        </label>
        <label>
          Benötigen Sie Unterstützung bei der Suchmaschinenoptimierung (SEO)?
          <select
            className="form-select"
            name="seoSupport"
            value={formData.seoSupport}
            onChange={handleChange}
          >
            <option value="yes">Ja</option>
            <option value="no">Nein</option>
          </select>
        </label>
        <label>
          Soll die Webseite mehrsprachig sein?
          <select
            className="form-select"
            name="multilingual"
            value={formData.multilingual}
            onChange={handleChange}
          >
            <option value="yes">Ja</option>
            <option value="no">Nein</option>
          </select>
        </label>
        {formData.multilingual === "yes" && (
          <label>
            Welche Sprachen?
            <input
              className="form-input"
              type="text"
              name="languages"
              value={formData.languages}
              onChange={handleChange}
            />
          </label>
        )}
        <div className="DashboardBtn2 Top">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step7;
