import React from "react";

const Step5 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    const { name, options } = e.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setFormData({ ...formData, [name]: selectedValues });
  };

  const customSelectStyle = {
    option: {
      backgroundColor: "white",
      color: "black",
    },
    optionSelected: {
      backgroundColor: "blue-grey",
      color: "white",
    },
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Inhalt und Funktionen</h2>
      </div>
      <form>
        <label>
          Welche Hauptseiten und Unterseiten benötigen Sie?
          <select
          className="form-select"
            multiple
            name="mainPages"
            value={formData.mainPages}
            onChange={handleChange}
          >
            {[
              "home",
              "about",
              "services",
              "blog",
              "contact",
              "faqs",
              "references",
              "portfolio",
              "shop",
              "reservations",
              "customerPortal",
              "other",
            ].map((option) => (
              <option
                key={option}
                value={option}
                style={
                  formData.mainPages.includes(option)
                    ? customSelectStyle.optionSelected
                    : customSelectStyle.option
                }
              >
                {option === "home" && "Startseite"}
                {option === "about" && "Über uns"}
                {option === "services" && "Leistungen/Produkte"}
                {option === "blog" && "Blog/News"}
                {option === "contact" && "Kontakt"}
                {option === "faqs" && "FAQs"}
                {option === "references" && "Referenzen/Kundenmeinungen"}
                {option === "portfolio" && "Portfolio"}
                {option === "shop" && "Shop"}
                {option === "reservations" && "Reservierungen/Buchungen"}
                {option === "customerPortal" && "Kundenportal"}
                {option === "other" && "Andere"}
              </option>
            ))}
          </select>
        </label>
        <label>
          Welche speziellen Funktionen benötigen Sie?
          <select
          className="form-select"
            multiple
            name="specialFunctions"
            value={formData.specialFunctions}
            onChange={handleChange}
          >
            {[
              "search",
              "contactForm",
              "newsletter",
              "userAccount",
              "shopFunctionality",
              "calendar",
              "blogSection",
              "socialMedia",
              "liveChat",
              "other",
            ].map((option) => (
              <option
                key={option}
                value={option}
                style={
                  formData.specialFunctions.includes(option)
                    ? customSelectStyle.optionSelected
                    : customSelectStyle.option
                }
              >
                {option === "search" && "Suchfunktion"}
                {option === "contactForm" && "Kontaktformular"}
                {option === "newsletter" && "Newsletter-Anmeldung"}
                {option === "userAccount" && "Benutzerkonto"}
                {option === "shopFunctionality" && "Online-Shop-Funktionalität"}
                {option === "calendar" && "Kalender/Terminbuchung"}
                {option === "blogSection" && "Blog/News-Bereich"}
                {option === "socialMedia" && "Integration sozialer Medien"}
                {option === "liveChat" && "Live-Chat"}
                {option === "other" && "Andere"}
              </option>
            ))}
          </select>
        </label>
        <label>
          Werden regelmäßig neue Inhalte veröffentlicht?
          <select
          className="form-select"
            name="regularContent"
            value={formData.regularContent}
            onChange={(e) =>
              setFormData({ ...formData, regularContent: e.target.value })
            }
          >
            <option value="yes">Ja</option>
            <option value="no">Nein</option>
          </select>
        </label>
        {formData.regularContent === "yes" && (
          <label>
            Welche Art von Inhalten?
            <textarea
              name="contentTypes"
              value={formData.contentTypes}
              onChange={(e) =>
                setFormData({ ...formData, contentTypes: e.target.value })
              }
            ></textarea>
          </label>
        )}
        <label>
          Haben Sie bereits Inhalte (Texte, Bilder, Videos) oder müssen diese
          erstellt werden?
          <textarea
            name="existingContent"
            value={formData.existingContent}
            onChange={(e) =>
              setFormData({ ...formData, existingContent: e.target.value })
            }
          ></textarea>
        </label>
        <div className="DashboardBtn2">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step5;
