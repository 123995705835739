import React from "react";
import Layout from "./../../components/layout";


const Dashboard = () => {

  return (
    <Layout>
      <section className="container">
öö
      </section>
    </Layout>
  );
};

export default Dashboard;
