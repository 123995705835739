// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-container {
    position: relative;
    display: inline-block;
    font-size: 24px;
    cursor: pointer;
  }

  .notification-bell {
    color: aliceblue;
    padding-right: 30px;
  }

  .notification-count {
    position: absolute;
    top: -10px;
    right: 15px;
    background: red;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 12px;
    opacity: 0.8;
  }

  .notification-dropdown {
    position: absolute;
    top: 30px;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 200px;
  }

  .notification-dropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: small;
  }

  .notification-dropdown li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }

  .notification-dropdown li:hover {
    background: #f0f0f0;
  }

  @media screen and (max-width: 700px) {
    .notification-bell {
      padding-right: 30px;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Socketio/Socketio.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,YAAY;EACd;;EAEA;IACE,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE;MACE,mBAAmB;IACrB;EACF","sourcesContent":[".notification-container {\r\n    position: relative;\r\n    display: inline-block;\r\n    font-size: 24px;\r\n    cursor: pointer;\r\n  }\r\n\r\n  .notification-bell {\r\n    color: aliceblue;\r\n    padding-right: 30px;\r\n  }\r\n\r\n  .notification-count {\r\n    position: absolute;\r\n    top: -10px;\r\n    right: 15px;\r\n    background: red;\r\n    color: white;\r\n    border-radius: 50%;\r\n    padding: 5px 10px;\r\n    font-size: 12px;\r\n    opacity: 0.8;\r\n  }\r\n\r\n  .notification-dropdown {\r\n    position: absolute;\r\n    top: 30px;\r\n    right: 0;\r\n    background: white;\r\n    border: 1px solid #ccc;\r\n    border-radius: 5px;\r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\r\n    z-index: 1000;\r\n    width: 200px;\r\n  }\r\n\r\n  .notification-dropdown ul {\r\n    list-style-type: none;\r\n    margin: 0;\r\n    padding: 0;\r\n    font-size: small;\r\n  }\r\n\r\n  .notification-dropdown li {\r\n    padding: 10px;\r\n    border-bottom: 1px solid #ccc;\r\n    cursor: pointer;\r\n  }\r\n\r\n  .notification-dropdown li:hover {\r\n    background: #f0f0f0;\r\n  }\r\n\r\n  @media screen and (max-width: 700px) {\r\n    .notification-bell {\r\n      padding-right: 30px;\r\n    }\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
