// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-list-header {
  display: flex;
  font-weight: bold;
  padding: 10px 0 10px 35px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 10px;
}

.form-header-id,
.form-header-company-name,
.form-header-website-type,
.form-header-created-at {
  flex: 1 1;
}

.form-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.form-list-item:last-child {
  border-bottom: none;
}

.form-id,
.form-company-name,
.form-website-type,
.form-created-at {
  flex: 1 1;
}

.form-icon {

  cursor: pointer;
}

.icon-top {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .formp {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }`, "",{"version":3,"sources":["webpack://./src/private/User/components/dashboard/auftrage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;;;;EAIE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;;;EAIE,SAAO;AACT;;AAEA;;EAEE,eAAe;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB","sourcesContent":[".form-list-header {\r\n  display: flex;\r\n  font-weight: bold;\r\n  padding: 10px 0 10px 35px;\r\n  border-bottom: 2px solid #ccc;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.form-header-id,\r\n.form-header-company-name,\r\n.form-header-website-type,\r\n.form-header-created-at {\r\n  flex: 1;\r\n}\r\n\r\n.form-list-item {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 10px 0;\r\n  border-bottom: 1px solid #ccc;\r\n}\r\n\r\n.form-list-item:last-child {\r\n  border-bottom: none;\r\n}\r\n\r\n.form-id,\r\n.form-company-name,\r\n.form-website-type,\r\n.form-created-at {\r\n  flex: 1;\r\n}\r\n\r\n.form-icon {\r\n\r\n  cursor: pointer;\r\n}\r\n\r\n.icon-top {\r\n    padding-top: 5px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n  }\r\n\r\n  .formp {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
