import React from 'react';

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100;

  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ height: '10px', width: '100%', backgroundColor: '#e0e0e0' }}>
        <div
          style={{
            height: '10px',
            width: `${progress}%`,
            backgroundColor: '#1888ff',
            transition: 'width 0.3s ease-in-out',
          }}
        ></div>
      </div>
      <p style={{ textAlign: 'center', marginTop: '5px' }}>
        Schritt {currentStep} von {totalSteps}
      </p>
    </div>
  );
};

export default ProgressBar;
