import React from "react";

const Step3 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Allgemeine Informationen</h2>
      </div>
      <form>
        <label>
          Welche Art von Webseite benötigen Sie?
          <select
            name="websiteType"
            value={formData.websiteType}
            onChange={handleChange}
            className="form-select"
          >
            <option value="onepager">Landingpage (Onepage)</option>
            <option value="multipage">
              Multipage mit Unterseiten (Starter)
            </option>
            <option value="fullstack">Full-Stack Applikation (Business)</option>
            <option value="other">Andere</option>
          </select>
        </label>
        <br />
        <label>
          Haben Sie bereits eine bestehende Webseite?
          <select
            name="existingWebsite"
            value={formData.existingWebsite}
            onChange={handleChange}
            className="form-select"
          >
            <option value="yes">Ja</option>
            <option value="no">Nein</option>
          </select>
        </label>
        {formData.existingWebsite === "yes" && (
          <div>
            <label>
              Bitte geben Sie die URL der bestehenden Webseite an:
              <input
                className="form-input"
                type="text"
                name="existingWebsiteURL"
                value={formData.existingWebsiteURL}
                onChange={handleChange}
              />
            </label>
            <label>
              Gründe für die Neugestaltung:
              <select
                className="form-select"
                name="redesignReasons"
                value={formData.redesignReasons}
                onChange={handleChange}
              >
                <option value="outdated">Veraltetes Design</option>
                <option value="notresponsive">Nicht responsiv</option>
                <option value="slow">Langsame Ladezeiten</option>
                <option value="missingfeatures">Fehlende Funktionen</option>
                <option value="other">Andere</option>
              </select>
            </label>
          </div>
        )}
        <div className="DashboardBtn2 Top">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step3;
