// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }
  
  .dashboardc {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
    width: 100%;
    max-width: 1200px;
  }
  
  .card {
    background: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.2s;
    height: 100px;
    box-sizing: border-box;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-icon {
    font-size: 24px;
    margin-bottom: 8px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .card-link {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 600px) {
    .dashboardc {
      grid-template-columns: 1fr;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/private/User/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,2DAA2D;IAC3D,SAAS;IACT,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,0BAA0B;IAC1B,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,cAAc;IACd,cAAc;IACd,WAAW;IACX,YAAY;EACd;;EAEA;IACE;MACE,0BAA0B;IAC5B;EACF","sourcesContent":[".container {\r\n    display: flex;\r\n    justify-content: center;\r\n    padding: 30px 0;\r\n  }\r\n  \r\n  .dashboardc {\r\n    display: grid;\r\n    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\r\n    gap: 16px;\r\n    width: 100%;\r\n    max-width: 1200px;\r\n  }\r\n  \r\n  .card {\r\n    background: #f5f5f5;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n    padding: 16px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    text-align: center;\r\n    transition: transform 0.2s;\r\n    height: 100px;\r\n    box-sizing: border-box;\r\n  }\r\n  \r\n  .card:hover {\r\n    transform: translateY(-5px);\r\n  }\r\n  \r\n  .card-icon {\r\n    font-size: 24px;\r\n    margin-bottom: 8px;\r\n  }\r\n  \r\n  .card-title {\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    margin-bottom: 8px;\r\n  }\r\n  \r\n  .card-link {\r\n    text-decoration: none;\r\n    color: inherit;\r\n    display: block;\r\n    width: 100%;\r\n    height: 100%;\r\n  }\r\n  \r\n  @media (max-width: 600px) {\r\n    .dashboardc {\r\n      grid-template-columns: 1fr;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
