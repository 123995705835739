import React from "react";

const Step8 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
      <h2>Integration und Schnittstellen</h2>
      </div>
      <form>
        <label>
          Benötigen Sie die Integration mit anderen Systemen oder Plattformen?
          <textarea
            name="integrationRequirements"
            value={formData.integrationRequirements}
            onChange={handleChange}
          ></textarea>
        </label>
        <div className="DashboardBtn2 Top">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step8;
