import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import React from "react";
import Profile from "./Dropdown/Profile/ProfileDropdown";
import { FaHome, FaUser, FaCog, FaSignOutAlt } from 'react-icons/fa';
import "./Navbar.css";
import { logout } from "./../../function/authService";
import Socketio from "./Socketio/Socketio";

const Navbar = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { userRole } = useSelector((state) => state.role);
  const isAdmin = userRole === "admin";
  const isUser = userRole === "user";
  const isWorker = userRole === "worker";
  const isCompany = userRole === "company";

  return (
    <nav className="navbar">
      <NavLink to="/home" className="navbar-logo">
        LOGO
      </NavLink>
      {isAuth && (
        <>
          <ul className="nav-menu">
            <li className="nav-item">
              <NavLink to="/home" className="nav-links">
                <FaHome className="nav-icon" />
                <span className="nav-text">Home</span>
              </NavLink>
            </li>
            {isUser && (
              <li className="nav-item">
                <NavLink to="/user/dashboard" className="nav-links">
                  <FaUser className="nav-icon" />
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li>
            )}
            {isWorker && (
              <li className="nav-item">
                <NavLink to="/worker/dashboard" className="nav-links">
                  <FaUser className="nav-icon" />
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li>
            )}
            {isCompany && (
              <li className="nav-item">
                <NavLink to="/company/dashboard" className="nav-links">
                  <FaUser className="nav-icon" />
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <NavLink to="/admin/dashboard" className="nav-links">
                  <FaUser className="nav-icon" />
                  <span className="nav-text">Dashboard</span>
                </NavLink>
              </li>
            )}
            {isWorker && (
              <li className="nav-item">
                <NavLink to="/worker/service" className="nav-links">
                  <FaCog className="nav-icon" />
                  <span className="nav-text">Service</span>
                </NavLink>
              </li>
            )}
            {isCompany && (
              <li className="nav-item">
                <NavLink to="/company/profile/create" className="nav-links">
                  <FaCog className="nav-icon" />
                  <span className="nav-text">Unternehmen</span>
                </NavLink>
              </li>
            )}
            {isWorker && (
              <li className="nav-item">
                <NavLink to="/worker/profile/create" className="nav-links">
                  <FaCog className="nav-icon" />
                  <span className="nav-text">Mitarbeiterprofil</span>
                </NavLink>
              </li>
            )}
            <li className="nav-item">
              <NavLink to="-" className="nav-links">
                <FaCog className="nav-icon" />
                <span className="nav-text">Contact Us</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="" className="nav-links" onClick={() => logout(dispatch)}>
                <FaSignOutAlt className="nav-icon" />
                <span className="nav-text"><div className="nav-text-l">Logout</div></span>
              </NavLink>
            </li>
          </ul>
          <Socketio />
          <Profile className="navbar-profile" />
        </>
      )}
    </nav>
  );
};

export default Navbar;
