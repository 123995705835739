import React from "react";

const Step12 = ({ formData, setFormData, prevStep, handleSubmit }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Zusätzliche Anmerkungen</h2>
      </div>
      <form>
        <label>
          Gibt es weitere Anforderungen oder Anmerkungen, die wir
          berücksichtigen sollten?
          <textarea
            name="additionalNotes"
            value={formData.additionalNotes}
            onChange={handleChange}
          ></textarea>
        </label>
        <div className="DashboardBtn2 Top">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={handleSubmit}>
            Absenden
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step12;
