import React from "react";

const Step10 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Rechtliche Anforderungen</h2>
      </div>
      <form>
        <label>
          Gibt es spezielle rechtliche Anforderungen, die berücksichtigt werden
          müssen?
          <textarea
            name="legalRequirements"
            value={formData.legalRequirements}
            onChange={handleChange}
          ></textarea>
        </label>
        <div className="DashboardBtn2 Top">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step10;
