//App.js
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { useSelector } from "react-redux";


import "./App.css";
import NotFound from "./pages/NotFound";
import NotRights from "./pages/NotRights";

import Signup from "./pages/signup";
import RequestPasswordReset from "./components/Signup/Login/requestPasswordReset";
import ResetPassword from "./components/Signup/Login/ResetPassword";
import OtpVerification from "./components/Signup/register/otpVerification";

import Profil from "./private/profil";
import Home1 from "./private/home";
import Service from "./private/service";


import UProfile from "./private/User/uProfil";
import DashboardU from "./private/User/dashboard";
import UserProfile from "./private/User/components/userProfile";
import Profilbild from "./private/User/components/profilbild";
import Zdata from "./private/User/zdaten";

import Userform from "./private/User/components/dashboard/multistepform";
import Usernews from "./private/User/components/dashboard/news";
import Userauftrage from "./private/User/components/dashboard/auftrage";
import AuftrageId from "./private/User/components/dashboard/auftrageId";
import Abgeschlossen from "./private/User/components/dashboard/abgeschlossen";

import DashboardC from "./private/Company/dashboard";
import DashboardW from "./private/Worker/dashboard";
import DashboardA from "./private/Admin/dashboard";
import AdminAuftrage from "./private/Admin/components/adminauftrage";
import AdminAuftrageId from "./private/Admin/components/adminAuftrageId";




const PrivateRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return <>{isAuth ? <Outlet /> : <Navigate to="/signup" />}</>;
};

const RestrictedRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return <>{!isAuth ? <Outlet /> : <Navigate to="/home" />}</>;
};

const App = () => {
  const { userRole } = useSelector((state) => state.role);
  const isAdmin = userRole === "admin";
  const isUser = userRole === "user";
  const isWorker = userRole === "worker";
  const isCompany = userRole === "company";

  return (
    <BrowserRouter>
      <Routes>

        <Route element={<PrivateRoutes />}>
          {isAdmin ? (
            <>
              <Route path="/admin/dashboard" element={<DashboardA />} />
              <Route path="/admin/form" element={<Userform />} />
              <Route path="/admin/news" element={<Usernews />} />
              <Route path="/admin/auftrage" element={<AdminAuftrage />} />
              <Route path="/admin/auftrage/:id" element={< AdminAuftrageId />} />
              <Route path="/admin/abgeschlossen" element={< Abgeschlossen />} />

              <Route path="/user/profile/settings" element={< UProfile />} />
              <Route path="/user/profile/data" element={< UserProfile />} />
              <Route path="/user/profile/picture" element={< Profilbild />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}
          {isUser ? (
            <>
              <Route path="/user/dashboard" element={<DashboardU />} />
              <Route path="/user/profile/settings" element={< UProfile />} />
              <Route path="/user/profile/data" element={< UserProfile />} />
              <Route path="/user/profile/picture" element={< Profilbild />} />
              <Route path="/user/profile/zdata" element={< Zdata />} />

              <Route path="/user/form" element={<Userform />} />
              <Route path="/user/news" element={<Usernews />} />
              <Route path="/user/auftrage" element={<Userauftrage />} />
              <Route path="/user/auftrage/:id" element={< AuftrageId />} />
              <Route path="/user/abgeschlossen" element={< Abgeschlossen />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}
          {isCompany ? (
            <>
              <Route path="/company/dashboard" element={<DashboardC />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}
          {isWorker ? (
            <>
              <Route path="/worker/dashboard" element={<DashboardW />} />
            </>
          ) : (
            <Route path="/*" element={<NotRights />} />
          )}

          <Route path="/home" element={<Home1 />} />
          <Route path="/profil" element={<Profil />} />
          <Route path="/service" element={<Service />} />
        </Route>

        <Route element={<RestrictedRoutes />}>
          <Route path="/signup" element={<Signup />} />
          <Route path="/request-password-reset" element={< RequestPasswordReset />} />
          <Route path="/reset-password" element={< ResetPassword />} />
          <Route path="/otp-verification" element={< OtpVerification />} />
        </Route>

        <Route path="*" element={<NotFound />} />
        <Route path="/*" element={<NotRights />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
