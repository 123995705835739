import React, { useState, useEffect } from "react";
import Layout from "../../../../components/layout";
import { useSelector } from "react-redux";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";
import "./auftrage.css";
import { useNavigate } from "react-router-dom";

const Auftrage = () => {
  const { userId } = useSelector((state) => state.user);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/form/${userId}`
        );
        const sortedForms = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setForms(sortedForms);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "An error occurred");
        setLoading(false);
      }
    };

    if (userId) {
      fetchForms();
    }
  }, [userId]);

  const handleRedirect = (id) => {
    navigate(`/user/auftrage/${id}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Layout>
      <section className="container">
        <div className="CardContainer">
          <div className="CardContainerTitle">
            <h2>Aufträge laufend</h2>
          </div>
          <div className="form-list-header">
            <div className="form-header-id">ID</div>
            <div className="form-header-company-name">Name</div>
            <div className="form-header-website-type">Service</div>
            <div className="form-header-created-at">Datum</div>
          </div>
          {forms.length > 0 ? (
            <ul className="form-list">
              {forms.map((form) => (
                <li
                  key={form.form_id}
                  className="form-list-item CardContainerTitle"
                >
                  <div className="form-id">{form.form_id}</div>
                  <div className="form-company-name">{form.company_name}</div>
                  <div className="form-website-type">{form.website_type}</div>
                  <div className="form-created-at">
                    {new Date(form.created_at).toLocaleDateString()}
                  </div>
                  <div className="icon-top">
                    <FaArrowRight
                      className="form-icon"
                      onClick={() => handleRedirect(form.form_id)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div>No forms found</div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default Auftrage;
