import { createSlice } from "@reduxjs/toolkit";

const workerIdFromLocalStorage = () => {
    const workerId = localStorage.getItem("workerId");
    return workerId ? parseInt(workerId) : "";
};

const initialWorkerState = {
    workerId: workerIdFromLocalStorage(),
};

export const workerSlice = createSlice({
    name: "worker_id",
    initialState: initialWorkerState,
    reducers: {
        setWorker: (state, action) => {
            state.workerId = action.payload;
        },
    },
});

export const { setWorker } = workerSlice.actions;

export const workerReducer = workerSlice.reducer;
