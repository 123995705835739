import React from 'react'
import Layout from './../../components/layout';

const zdaten = () => {
  return (
    <Layout>
        <div>
            <h1>Meine Daten</h1>
        </div>
    </Layout>
  )
}

export default zdaten