import React from "react";

const Step2 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Ansprechpartner</h2>
      </div>
      <form>
        <label>
          Vorname:
          <input
            className="form-input"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </label>
        <label>
          Nachname:
          <input
            className="form-input"
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </label>
        <label>
          Telefonnummer:
          <input
            className="form-input"
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </label>
        <label>
          E-Mail-Adresse:
          <input
            className="form-input"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </label>
        <div className="DashboardBtn2 Top">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step2;
