import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Layout from "./../../../components/layout";
import "./../../User/components/dashboard/auftrage.css";

const AdminFormDetail = () => {
  const { id } = useParams();
  const { userId } = useSelector((state) => state.user);
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(""); // State für den Status
  const [updating, setUpdating] = useState(false); // State für den Button-Status

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/forms/${id}`
        );

        const formId = parseInt(id, 10);
        const selectedForm = response.data.find((f) => f.form_id === formId);

        if (selectedForm) {
          setForm(selectedForm);
          setStatus(selectedForm.status || ""); // Status setzen, falls vorhanden
        } else {
          setError("Form not found");
        }

        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "An error occurred");
        setLoading(false);
      }
    };

    if (id) {
      fetchForm();
    }
  }, [id]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleStatusUpdate = async () => {
    setUpdating(true);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/form/status/${userId}/${id}`,
        { status }
      );
      console.log("Status updated:", response.data);
      setUpdating(false); // Button wieder aktivieren
    } catch (err) {
      console.error("Error updating status:", err);
      setUpdating(false); // Button wieder aktivieren
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!form) {
    return <div>No form found</div>;
  }

  return (
    <Layout>
      <section className="container">
        <div className="CardContainer">
          <div className="form-detail">
            <div className="CardContainerTitle">
              <h2>Form Details</h2>
            </div>
            <div className="">
              <p>
                <strong>ID:</strong> {form.form_id}
              </p>
              <p>
                <strong>Company Name:</strong> {form.company_name}
              </p>
              <p>
                <strong>Service Type:</strong> {form.website_type}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {new Date(form.created_at).toLocaleDateString()}
              </p>
            </div>
          </div>
      <div className="Text1 Top">
        <h3>Update Status</h3>
        <select className="form-select" value={status} onChange={handleStatusChange}>
          <option value="">Status auswählen</option>
          <option value="Storniert">Storniert</option>
          <option value="Anstehend">Anstehend</option>
          <option value="Erst Kontakt">Erst Kontakt</option>
          <option value="In Bearbeitung">In Bearbeitung</option>
          <option value="Abgeschlossen">Abgeschlossen</option>
        </select>
        <br/>
        <button  className="btn-gl"
          onClick={handleStatusUpdate} 
          disabled={updating || status === ""}
        >
          {updating ? "Updating..." : "Update Status"}
        </button>
      </div>
        </div>
      </section>
    </Layout>
  );
};

export default AdminFormDetail;
