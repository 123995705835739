import React from "react";

const Step9 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Wartung und Support</h2>
      </div>
      <form>
        <label>
          Benötigen Sie fortlaufende Wartung und Support nach der Fertigstellung
          der Webseite?
          <select
            className="form-select"
            name="maintenanceSupport"
            value={formData.maintenanceSupport}
            onChange={handleChange}
          >
            <option value="yes">Ja</option>
            <option value="no">Nein</option>
          </select>
        </label>
        {formData.maintenanceSupport === "yes" && (
          <label>
            Welche Art von Support benötigen Sie?
            <textarea
              name="supportType"
              value={formData.supportType}
              onChange={handleChange}
            ></textarea>
          </label>
        )}
        <div className="DashboardBtn2 Top">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step9;
