import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Layout from "../../../../components/layout";

const FormDetail = () => {
  const { id } = useParams();
  const userId = useSelector((state) => state.user.userId);
  const [form, setForm] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFormDetails = async () => {
      try {
        const formResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/form/${userId}/${id}`
        );
        const statusResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/form/status/${userId}/${id}`
        );

        setForm(formResponse.data);
        setStatus(statusResponse.data.status); // Den Status separat speichern
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "An error occurred");
        setLoading(false);
      }
    };

    if (userId && id) {
      fetchFormDetails();
    }
  }, [userId, id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!form) {
    return <div>No form found</div>;
  }

  return (
    <Layout>
      <section className="container">
        <div className="CardContainer">
          <div className="form-detail">
            <div className="CardContainerTitle">
              <h2>Form Details</h2>
            </div>
            <div className="">
              <p>
                <strong>ID:</strong> {form.form_id}
              </p>
              <p>
                <strong>Company Name:</strong> {form.company_name}
              </p>
              <p>
                <strong>Service Type:</strong> {form.website_type}
              </p>
              <p>
                <strong>Status:</strong> {status || "No status available"} {/* Status anzeigen */}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {new Date(form.created_at).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FormDetail;
