import React from "react";

const Step1 = ({ formData, setFormData, nextStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Unternehmensinformationen</h2>
      </div>
      <form className="">
        <label>
          Name des Unternehmens:
          <input
            className="form-input"
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
          />
        </label>
        <label>
          Adresse:
          <input
            className="form-input"
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </label>
        <label>
          Straße und Hausnummer:
          <input
            className="form-input"
            type="text"
            name="street"
            value={formData.street}
            onChange={handleChange}
          />
        </label>
        <label>
          Postleitzahl:
          <input
            className="form-input"
            type="text"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
          />
        </label>
        <label>
          Stadt:
          <input
            className="form-input"
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </label>
        <label>
          Bundesland:
          <input
            className="form-input"
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
        </label>
        <label>
          Steuernummer:
          <input
            className="form-input"
            type="text"
            name="taxNumber"
            value={formData.taxNumber}
            onChange={handleChange}
          />
        </label>
        <div className="DashboardBtn1 Top">
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step1;
