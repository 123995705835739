import React, { useEffect, useState, useRef } from "react";
import { FaBell } from "react-icons/fa";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Socketio.css";

function Socketio() {
  const [notifications, setNotifications] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const userId = useSelector((state) => state.user.userId);
  const navigate = useNavigate();

  // Funktion zum Laden von Benachrichtigungen aus dem Local Storage
  const loadNotifications = () => {
    const storedNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];
    setNotifications(storedNotifications);
  };

  // Funktion zum Speichern von Benachrichtigungen im Local Storage
  const saveNotifications = (newNotifications) => {
    localStorage.setItem("notifications", JSON.stringify(newNotifications));
  };

  useEffect(() => {
    loadNotifications(); // Benachrichtigungen beim Laden der Seite abrufen

    const socket = io(process.env.REACT_APP_SOCKETIO_URL, {
      secure: true,
      transports: ["websocket", "polling"], // Beide Transportmethoden zulassen
      withCredentials: true, // Cookies und Authentifizierungs-Header zulassen
    });

    if (userId) {
      socket.emit("joinRoom", userId);
    }

    socket.on("statusUpdated", (data) => {
      const newNotification = {
        title: `Status Update`,
        message: `Der Status Ihres Formulars mit der ID ${data.formId} wurde aktualisiert auf: ${data.status}`,
        formId: data.formId,
      };

      setNotifications((prevNotifications) => {
        const updatedNotifications = [
          newNotification,
          ...prevNotifications,
        ].slice(0, 5);
        saveNotifications(updatedNotifications); // Benachrichtigungen speichern
        return updatedNotifications;
      });
    });

    return () => {
      socket.disconnect();
    };
  }, [userId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNotificationClick = (formId) => {
    // Navigiere zur Auftragsseite
    navigate(`/user/auftrage/${formId}`);

    // Aktualisiere die Benachrichtigungen und entferne die geklickte Benachrichtigung
    setNotifications((prevNotifications) => {
      const updatedNotifications = prevNotifications.filter(
        (notification) => notification.formId !== formId
      );
      saveNotifications(updatedNotifications); // Aktualisiere den Local Storage
      return updatedNotifications;
    });
  };

  return (
    <div>
      <div
        className="notification-container"
        onClick={toggleDropdown}
        ref={dropdownRef}
      >
        <div className="notification-bell">
          <FaBell />
        </div>
        {notifications.length > 0 && (
          <span className="notification-count">{notifications.length}</span>
        )}
        {dropdownOpen && (
          <div className="notification-dropdown">
            <ul>
              {notifications.map((notification, index) => (
                <li
                  key={index}
                  onClick={() => handleNotificationClick(notification.formId)}
                >
                  <strong>{notification.title}</strong>
                  <p>{notification.message}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Socketio;
