import React from "react";

const Step4 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Ziel und Zielgruppe</h2>
      </div>
      <form>
        <label>
          Was ist das Hauptziel Ihrer Webseite?
          <select
            className="form-select"
            name="mainGoal"
            value={formData.mainGoal}
            onChange={handleChange}
          >
            <option value="information">Informationen bereitstellen</option>
            <option value="sales">Produkte/Dienstleistungen verkaufen</option>
            <option value="contacts">Kontaktanfragen generieren</option>
            <option value="engagement">Kundenbindung erhöhen</option>
            <option value="awareness">Markenbekanntheit steigern</option>
            <option value="other">Sonstiges</option>
          </select>
        </label>

        <label>
          Wer ist Ihre Zielgruppe?
          <textarea
            name="targetAudience"
            value={formData.targetAudience}
            onChange={handleChange}
          ></textarea>
        </label>

        <div className="DashboardBtn2">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step4;
