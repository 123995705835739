import React, { useState } from 'react';
import Layout from '../../../../components/layout';
import MultiStepForm from './multistepform/MultiStepForm';
import Confirm from "./multistepform/Confirmation"

const Multistepform = () => {
  const [confirmed, setConfirmed] = useState(false);

  const handleConfirm = () => {
    setConfirmed(true);
  };

  return (
    <Layout>
      <section className='container'>
        {!confirmed ? (
          <Confirm onConfirm={handleConfirm} />
        ) : (
          <MultiStepForm />
        )}
      </section>
    </Layout>
  );
};

export default Multistepform;
