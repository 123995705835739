import React from 'react'
import Layout from '../../../../components/layout'

const Abgeschlossen = () => {
  return (
    <Layout>
        <section className='container'>
          <div className='CardContainer'>
            <div className='CardContainerTitle'>
              <h1>Abgeschlossen</h1>
            </div>
            <p>Herzlichen Glückwunsch! Du hast alle Aufgaben erledigt.</p>
          </div>
        </section>
    </Layout>
  )
}

export default Abgeschlossen