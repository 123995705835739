import React from "react";

const Step6 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
      <h2>Design</h2>
      </div>
      <form>
        <label>
          Haben Sie ein bestehendes Logo und Farbschema?
          <select
            name="existingLogo"
            value={formData.existingLogo}
            onChange={handleChange}
            className="form-select"
          >
            <option value="yes">Ja</option>
            <option value="no">Nein</option>
          </select>
        </label>
        {formData.existingLogo === "yes" && (
          <label>
            Bitte hochladen oder beschreiben:
            <textarea
              name="logoDescription"
              value={formData.logoDescription}
              onChange={handleChange}
            ></textarea>
          </label>
        )}
        <label>
          Welche Art von Design bevorzugen Sie?
          <select
          className="form-select"
            name="designPreference"
            value={formData.designPreference}
            onChange={handleChange}
          >
            <option value="modern">Modern</option>
            <option value="classic">Klassisch</option>
            <option value="minimalistic">Minimalistisch</option>
            <option value="playful">Verspielt</option>
            <option value="elegant">Elegant</option>
            <option value="technical">Technisch</option>
            <option value="other">Sonstiges</option>
          </select>
        </label>
        <label>
          Gibt es spezifische Farben oder Schriftarten, die verwendet werden
          sollen?
          <select
          className="form-select"
            name="specificColorsFonts"
            value={formData.specificColorsFonts}
            onChange={handleChange}
          >
            <option value="yes">Ja</option>
            <option value="no">Nein</option>
          </select>
        </label>
        {formData.specificColorsFonts === "yes" && (
          <div>
            <label>
              Farben:
              <input
              className="form-input"
                type="text"
                name="colors"
                value={formData.colors}
                onChange={handleChange}
              />
            </label>
            <label>
              Schriftarten:
              <input
              className="form-input"
                type="text"
                name="fonts"
                value={formData.fonts}
                onChange={handleChange}
              />
            </label>
          </div>
        )}
        <label>
          Gibt es bestehende Webseiten oder Designs, die Ihnen besonders
          gefallen?
          <select
          className="form-select"
            name="designExamples"
            value={formData.designExamples}
            onChange={handleChange}
          >
            <option value="yes">Ja</option>
            <option value="no">Nein</option>
          </select>
        </label>
        {formData.designExamples === "yes" && (
          <label>
            Bitte Beispiele und/oder Links angeben:
            <textarea
              name="designExamplesLinks"
              value={formData.designExamplesLinks}
              onChange={handleChange}
            ></textarea>
          </label>
        )}
        <label>
          Gibt es spezifische Designelemente, die Sie bevorzugen oder vermeiden
          möchten?
          <textarea
            name="designElements"
            value={formData.designElements}
            onChange={handleChange}
          ></textarea>
        </label>
        <div className="DashboardBtn2 Top">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step6;
