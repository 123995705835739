import React from "react";

const Step11 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Budget und Zeitrahmen</h2>
      </div>
      <form>
        <label>
          Was ist Ihr Budget für die Webseite?
          <input
            className="form-input"
            type="text"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
          />
        </label>
        <label>
          Gibt es einen festen Zeitrahmen für die Fertigstellung der Webseite?
          <input
            className="form-input"
            type="text"
            name="timeline"
            value={formData.timeline}
            onChange={handleChange}
          />
        </label>
        <div className="DashboardBtn2 Top">
          <button className="btn-gl" onClick={prevStep}>
            Zurück
          </button>
          <button className="btn-gl" onClick={nextStep}>
            Weiter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step11;
