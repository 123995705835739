import React from "react";

const Confirmation = ({ onConfirm }) => {
  return (
    <div className="CardContainer">
      <div className="CardContainerTitle">
        <h2>Bitte bestätigen Sie</h2>
      </div>
      <form>
        <div className="Text2">
          <p>
            Bevor Sie das Formular ausfüllen, stellen Sie sicher, dass der Ansprechpartner
            etwa 10 Minuten Zeit hat.
          </p>
          <br />
          <p>
          Dieses Formular hilft uns dabei, die Anforderungen für die neue Webseite zu sammeln. Bitte füllen Sie es so detailliert wie möglich aus, damit wir Ihre Bedürfnisse bestmöglich nachvollziehen können.
          </p>
        </div>
        <div className="DashboardBtn Top">
          <button className="btn-gl" onClick={onConfirm}>
            Ich stimme zu
          </button>
        </div>
      </form>
    </div>
  );
};

export default Confirmation;
