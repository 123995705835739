import React, { useState } from "react";
import { useSelector } from "react-redux";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Step7 from "./step7";
import Step8 from "./step8";
import Step9 from "./step9";
import Step10 from "./step10";
import Step11 from "./step11";
import Step12 from "./step12";
import ProgressBar from "./Progressbar";

const MultiStepForm = () => {
  const { userId } = useSelector((state) => state.user);

  // Gesamtzahl der Schritte
  const totalSteps = 12;

  const [formData, setFormData] = useState({
    companyName: "",
    address: "",
    street: "",
    postalCode: "",
    city: "",
    state: "",
    taxNumber: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    websiteType: "",
    existingWebsite: "no",
    existingWebsiteURL: "",
    redesignReasons: "",
    mainGoal: "",
    targetAudience: "",
    mainPages: [],
    specialFunctions: [],
    regularContent: "no",
    contentTypes: "",
    existingContent: "",
    existingLogo: "no",
    logoDescription: "",
    designPreference: "",
    specificColorsFonts: "no",
    colors: "",
    fonts: "",
    designExamples: "no",
    designExamplesLinks: "",
    designElements: "",
    technicalRequirements: "",
    seoSupport: "no",
    multilingual: "no",
    languages: "",
    integrationRequirements: "",
    maintenanceSupport: "no",
    supportType: "",
    legalRequirements: "",
    budget: "",
    timeline: "",
    additionalNotes: "",
  });

  const [step, setStep] = useState(1);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/form/${userId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        console.log("Form submitted successfully");
      } else {
        console.log("Error submitting form");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div>
      {/* ProgressBar anzeigen */}
      <ProgressBar currentStep={step} totalSteps={totalSteps} />

      {/* Schrittweises Formular */}
      {step === 1 && (
        <Step1
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
        />
      )}
      {step === 2 && (
        <Step2
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 3 && (
        <Step3
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 4 && (
        <Step4
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 5 && (
        <Step5
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 6 && (
        <Step6
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 7 && (
        <Step7
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 8 && (
        <Step8
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 9 && (
        <Step9
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 10 && (
        <Step10
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 11 && (
        <Step11
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 12 && (
        <Step12
          formData={formData}
          setFormData={setFormData}
          prevStep={prevStep}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default MultiStepForm;
