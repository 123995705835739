// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.auth-buttons {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.btn-gl1 {
  padding: 8px 20px;
  border-radius: 4px 0px 0 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.btn-gl2 {
  padding: 8px 20px;
  border-radius: 0px 4px 4px 0px ;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.Register {
  padding-bottom: auto;
}

.Geschlecht {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.G-Radio {
  display: inline-block;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Signup/signup.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,4BAA4B;EAC5B,aAAa;EACb,YAAY;EACZ,eAAe;EACf,WAAW;EACX,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,+BAA+B;EAC/B,aAAa;EACb,YAAY;EACZ,eAAe;EACf,WAAW;EACX,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".auth-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding-top: 50px;\r\n}\r\n\r\n.auth-buttons {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  padding-bottom: 25px;\r\n}\r\n\r\n.btn-gl1 {\r\n  padding: 8px 20px;\r\n  border-radius: 4px 0px 0 4px;\r\n  outline: none;\r\n  border: none;\r\n  font-size: 18px;\r\n  color: #fff;\r\n  cursor: pointer;\r\n  background-color: var(--primary);\r\n}\r\n\r\n.btn-gl2 {\r\n  padding: 8px 20px;\r\n  border-radius: 0px 4px 4px 0px ;\r\n  outline: none;\r\n  border: none;\r\n  font-size: 18px;\r\n  color: #fff;\r\n  cursor: pointer;\r\n  background-color: var(--primary);\r\n}\r\n\r\n.Register {\r\n  padding-bottom: auto;\r\n}\r\n\r\n.Geschlecht {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.G-Radio {\r\n  display: inline-block;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding-bottom: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
