import React from 'react'
import Layout from '../../../../components/layout'

const News = () => {
  return (
    <Layout>
        <section className='container'>
            <div>News</div>
        </section>
    </Layout>
  )
}

export default News